.NewTunnelModal{

  .switch-tag{
    display: inline-block;
    min-width: 100px;
    border: 1px solid #2b3553;
    border-radius:0.4285rem;
    transform: translateY(-4px);
    margin: 0 8px;
    &.active{
      background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    }
  }
  .fixed-device{
    text-align: center;
    max-width: 273px;
    margin: 0 auto 10px;
    border-radius: 0.4285rem;
    border: 1px solid #2b3553;
    font-size: 1.4em;
  }

  .form-control, .EntitySelect{
    max-width: 273px;
    margin: 0 auto 10px;
  }
}
