.CompanyDevices{
  hr{
    background-color: lightgray;
  }
  .end-spacer{
    height: 100px;
    width: 100%;
  }

  .project-title{
    .btn-container{
      float: right;
    }
    br{
      display: none;
    }
    hr{
      margin: 0 0 16px 0;
    }
    @media only screen and (max-width: 768px) {
      .btn-container{
        float: none;
      }
      text-align: center;
      br{
        display: initial;
      }
    }

  }
  .add-project-btn {
    float: right;
    @media only screen and (max-width: 768px) {
      float: none;
    }
  }
}
