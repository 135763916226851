.card {
  .comments-history {
    display: inline-block;
    width: 100%;
    height: 150px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: scroll;
    background-color: #1f1024;
    overflow-x: hidden;

    &::-webkit-scrollbar{
      background-color: transparent;
      width: 4px;
      height: 5px;
    }
    &::-webkit-scrollbar-button{
      display: none;
    }
    &::-webkit-scrollbar-track{
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      background-color: rgba(255,255,255,.4);
      width: 4px;
      border-radius: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-corner{
      display: none;
    }

    .comment {
      background-color: #1e1e26;
      font-size: 15px;
      padding: 5px 15px;
      border-bottom: 1px solid #fff;
      position: relative;

      &-delete {
        position: absolute;
        top: 5px;
        right: 15px;
        height: 20px;
        cursor: pointer;
        width: 12px;

        svg{
          fill: white;
          pointer-events: none;

          & > * {
            pointer-events: none;
          }
        }
      }

      &-edition {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        background-color: #1e1e27;
        z-index: 100;
        border-radius: 15px;
        text-align: center;

        h3 {
          margin: 15px;
        }

        &-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          transform: translate(-15px, 0);
          background-color: #3c423cad;
          z-index: 50;
          border-radius: 5px;
        }
      }

      &-show-edition {
        background-color: #343444;
        color: white;
        width: 95%;
        height: 125px;
        border: none;
        font-size: 13px;
        padding: 0 5px;
        resize: none;
        outline: none;
      }

      &-show-text {
        background-color: #1e1e28;
        color: white;
        width: 90%;
        border: none;
        font-size: 13px;
        white-space: pre-wrap;
        padding: 0 5px;
        resize: none;
        outline: none;

        &::-webkit-scrollbar{
          background-color: transparent;
          width: 4px;
          height: 5px;
        }
        &::-webkit-scrollbar-button{
          display: none;
        }
        &::-webkit-scrollbar-track{
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb{
          background-color: rgba(255,255,255,.4);
          width: 4px;
          border-radius: 10px;
          height: 5px;
        }
        &::-webkit-scrollbar-corner{
          display: none;
        }

      }

      &-edit {
        position: absolute;
        bottom: 8px;
        right: 25px;
        font-size: 12px;
        background-color: #ad6a07;
        padding: 2px 12px;
        border: none;
        font-weight: 600;
        color: white;
        cursor: pointer;
        border-radius: 15px;
        outline: none;

        &:hover {
          transform: translateY(-1px);
          background-color: #784a06;
          box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.45);
        }
      }

      &-send {
        font-size: 12px;
        background-color: #17a2b8;
        padding: 2px 12px;
        border: none;
        font-weight: 600;
        color: white;
        cursor: pointer;
        margin: 10px 15px;
        border-radius: 15px;
        outline: none;

        &:hover {
          transform: translateY(-1px);
          background-color: #138496;
          box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.45);
        }
      }

      &-cancel {
        font-size: 12px;
        background-color: #c40000;
        padding: 2px 12px;
        border: none;
        margin: 10px 15px;
        font-weight: 600;
        color: white;
        cursor: pointer;
        border-radius: 15px;
        outline: none;

        &:hover {
          transform: translateY(-1px);
          background-color: #920000;
          box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.45);
        }
      }

      &-extra-info {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          & > li:first-child {
            padding-left: 0;
          }

          li {
            display: inline-block;
          font-weight: 600  ;
            font-size: 12px;
            padding: 0 15px;
          }
        }
      }
    }
  }

  .comments-submit {
    padding-top: 15px;

    .comment-text {
      width: 100%;
      border-radius: 5px;
      border: none;
      font-size: 15px;
      outline: none;
      height: 70px;
      padding: 5px;
      margin-bottom: 15px;
      line-height: 20px;
      resize: none;
    }
  }

  .error-comment {
    position: absolute;
    width: 40%;
    height: 30%;
    background-color: #8a171c;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .error-text {
      padding: 17px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}