/* App wide styles */
.card.highlitable {
  cursor: pointer;
  transition: box-shadow 300ms, transform 300ms;
  &:hover {
    box-shadow: -3px 3px 10px 0px;
    transform: translateY(-3px);
  }
}

input.full-container-file{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-control:focus {
  border-color: #0000bc;
}

/* Fix broken InputGroupAddon s */
.input-group .form-control:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  border-right: none;
}

.input-group-append{
  border: none;
  .input-group-text{
    padding-left: 5px !important;
  }
}
/*
.input-group-append, .input-group-prepend{
  background-color: #f3f3f5;
}
*/

.form-control + .input-group-append .input-group-text, .form-control + .input-group-prepend .input-group-text{
  background: none;
}

.form-control:focus + .input-group-append .input-group-text,
.form-control:focus ~ .input-group-append .input-group-text,
.form-control:focus + .input-group-prepend .input-group-text,
.form-control:focus ~ .input-group-prepend .input-group-text {
  border-color: #0000bc;
}

.input-group-append .input-group-text,
.input-group .form-control + .input-group-append .input-group-text{
  padding: 6px 18px 6px 0;
}

/* Fixes form styles over light backgrounds */
.dark-form{
  input, input:focus{
    color: #222a42;
  }

  .input-group-text{
    color: #222a42;
  }
}

/* Fix button icons alignment */
.btn:not(.btn-icon) .tim-icons{
  top: -1px;
}

/* Removes scroll in empty pages */
.main-panel > .content {
  min-height: calc(100vh - 84px);
}

.fixed-plugin {
  transform: translateX(34px);
  transition: transform 0.5s;
  &:hover{
    transform: translateX(0);
  }
}

.code-textarea{
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #f3a4b5;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  resize: none;
}

.modal{

  color: white;

  .modal-content .modal-header .modal-title {
    color: #f9f6ff;
  }

  .close > span:not(.sr-only) {
    color: rgba(255, 255, 255, 0.6);
  }

 .modal-content {
   background-color: #1d1d2b;

   .modal-body p {
     color: white;
   }

   @media (min-width: 576px) {
     box-shadow: 0 15px 35px rgba(73, 222, 190, 0.15), 0 5px 15px rgba(255, 255, 255, 0.09);
   }
 }
}
/* Fix white letters over white background */
.EntitySelect{
  color: darkblue;
}

/* Fix scroll bar when hidden */
.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
  overflow-x: hidden;
}

.link{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

/* Overriding react-dates styles */
.DateInput_input__focused{
    border-bottom: 2px solid #fd5f93;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
    background: #fd5f93;
    border: 1px double #fd5f93;
}
.CalendarDay__selected_span{
    background: #ff94b8;
    border: 1px double #fd5f93;
}
.CalendarDay__selected_span:hover{
    background: #fd5f93;
    border: 1px double #fd5f93;
}


/*For <select/>'s*/
option {
    background-color: #27293d;
}


.input-search {
  width: 20vw !important;
}