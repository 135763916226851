div .TunnelCard{
  display: flex;
  text-align: center;
  flex-direction: row;
  padding: 20px;
  &.has-alias{
    padding: 10px 20px;
  }
  .background{
    background-image: url("../../views/LocalClient/assets/tunnel-arrow.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.1;
    top: 0;
    left: 0;
    &.reverse{
      transform: scaleX(-1);
    }
  }
  & :not(.background){
    z-index: 1;
  }
  &.has-alias .port{
    padding-top: 10px;
  }
  .port{
    flex: 1;
    font-weight: bold;
    .icon{
      font-size: 2.3em;
    }
    p{
      font-size: 1.2em;
    }
  }
  .host{
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      flex-grow: 1;
      font-size: 1.6em;
    }
    .btn{
      min-width: 75px;
    }
  }
  p.alias{
    font-size: 1em;
    margin: 0;
  }
}
