.ConsoleAsync {
  .console {
    font-family: Courier;
    background-color: #000000; 
    color: #99ff00;
    display:block; 
    width: 100%;
    max-height: 30vh;
    padding: 0.2rem;
    overflow-y: auto;
  }
  .history {
    white-space: pre;
  }
  .command {
    margin-top: 0.2rem;
    .command-text {
      font-family: Courier;
      border: none;
      overflow: auto;
      outline: none;
      width: 100%;
      height: 100%;
      background: #000000;
      resize: none;
      color: #99ff00;
    }
  }
  .text-history {
    color: #74bf04;
  }
  .hist-result {
    color: #ffffff;
  }
  .pending {
    color: aqua;
  }
  .sent {
    color: yellow;
  }
  .executed {
    color: #74bf04;
  }
  .errored {
    color: red;
  }
}