.DevicePill{
  .card.device-card{
    display: block;
    padding-left: 8px;
    position: relative;

    .serial, .company{
      font-size: 0.8em;
      color: #525f7f;
    }
    .company{
      color: white;
    }
    .name{
      color: white;
      font-size: 1.1em;
    }

    .color{
      content:' ';
      position: absolute;
      height: 100%;
      width: 5px;
      left: 0;
      border-radius: 5px 0 0 5px;
      background-color: #fa0008;
    }

    .badges{
      position: absolute;
      top: -5px;
      right: -6px;
      .badge{
        font-size: 16px;
        background-color: red;
        color: white;
        padding: 2px 6px;
        border-radius: 10px;
        border: 1px solid white;
        display: inline-block;
        width: 20px;
        height: 20px;

        &.good{
          background-color: #7fec9c;
        }

        svg{
          position: relative;
          left: -1px;
          top: -1px;
        }
        &.throttle svg{
          left: -4px;
          top: 1px;
          font-size: 14px;
        }
        &.appsMismatch {
        position: relative;
          top:-1px;
          svg {
            left: -4px;
            font-size: 14px;
          }
        }
        &.storage svg{
          left: -2.2px;
        }
        &.ssh svg{
          top: 0.5px;
          left: -3.5px;
          font-size: 12px;
        }
        &.statLogsEnabled svg{
            left: -3px;
        }

      }
    }
  }
}
