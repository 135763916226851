.DeviceStats{
    .stats-card{
        padding: 15px 10px;
    }
    .stat-controls{
        margin-bottom: 20px;
    }

    // Overriden date picker styles for control
    .DateRangePicker{
        width: 100%;
    }
    .DateRangePickerInput{
        width: 100%;
        height: calc(2.25rem + 2px);
    }
    .DateInput_input{
        height: calc(2.25rem + 1px);
    }
    .DateInput.DateInput_1{
        width: calc(50% - 12px);
    }
    .DayPickerKeyboardShortcuts_buttonReset{
        display: none;
    }
}
