.category-column {
  &:hover {
    color: white;
    cursor: pointer;
    font-weight: 600;
  }
}

.modal-devices {
  margin: 0;
  font-size: 12px;

  &-section {
    font-weight: 600;
  }
}