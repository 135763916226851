.DeviceInfoPublic {
  border-top: 2px solid #e14eca;
  padding-left: 15px;
  padding-right: 15px;

  .device-title{
    margin-bottom: 10px;
    span{
      white-space: pre;
    }
  }

  .connection-dot{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: greenyellow;
    margin: 3px;
  }
  .card-action{

    .info-icon{
      cursor: pointer;
    }

    .code-textarea{
      min-height: 75px;
    }
  }
  .control-card {
    .control-bar{
      border-bottom: 1px solid #f65d93;
      padding: 5px;

    }
    .control-prop{
      font-size: 1.5em;
    }
  }

  .command-block{
    background-color: #1e1e2e;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 30px;

    h4{
      font-size: 1.6em;
      margin: 0;
    }

    .bootstrap-switch{
      margin: 15px 10px 0 0;
    }
  }

  .trash-cell{
    width: 40px;
    color: red;
    .icon{
      cursor: pointer;
    }
  }
}