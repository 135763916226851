.Dashboard{
  h2 a{
    color: rgba(255, 255, 255, 0.8);
  }
  .online-title{
    background-color: #27293c;
    margin: 0 0 20px 0;
    border-radius: 5px;
    padding: 10px;
  }
}
